.MuiTableRow-root > * {
    padding: 10px;
  }
  
  .MuiTableRow-root.MuiTableRow-head > * {
    font-weight: 100 !important;    
  }
  
  .Table td,
  th {
    border: none !important;
    font-weight: bold !important;
    padding-bottom: 2px !important;
    padding: 10px 20px 8px 20px;
  }
  
  .Table:first-child {
    border-radius: 0.7rem !important;
  }
  

  .status {
    align-content: center;
    text-align: center;
    padding: 10px 60px 8px 60px;
    border-radius: 9px;
  }
  
  .Details {
    color: #00b5ff !important;
  }

  .body {
    
  }
  
  @media screen and (max-width: 1279px) {
    .Table {
      width: 120%;
      margin-top: 2rem;
    }
    .status {
      padding: 10px 20px 10px 20px;
      border-radius: 9px;
    }

  }

  @media screen and (max-width: 1535px) {
    .Table {
      /* width: 50%; */
      margin-top: 0.5rem;
    }
  }
  
  
  @media screen and (max-width: 1023px) {
    .Table{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 240%;
    }
  }
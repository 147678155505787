@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Nunito', sans-serif;
    background-image: url("img/bg_test3.png");
    background-size: 100%;
    
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    background-attachment: fixed;
    background-color: rgba(256,256,256,1);
  } 

  

  .menu {
    background: none;
    font-size: 1.125rem/* 18px */;
  }
  @media (min-width: 768px) {
    .menu {
        font-size:  12px;
        
    }
  }

  @media (min-width: 1024px) {
    .menu {
        font-size:  14px;
        
    }
  }
 

  .bg-tg-dollars {
    background-image: url("img/bg_tg.png");
  }

  .sticky {    
    background-color: rgba(256,256,256,1);
    position: sticky;
    top: 0px;
    z-index: 100;
  }

  a:hover {
    color: red;
  }


  @media
  (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
   }
  }

  @media (max-width: 1023px) {
    .headerMenu {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .menuIcon {
      display: none;
    }
  }

}